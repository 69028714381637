<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">VISA TYPE</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearch()"
                v-model="search"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">VISA TYPE</th>
                      <!-- <th class="text-center text-uppercase">DETAIL</th>
                    <th class="text-center text-uppercase">INDEX</th> -->
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.jenis_visa }}</td>
                      <!-- <td class="text-center">
                      {{ data.detail }}
                    </td>
                    <td class="text-center">
                      {{ data.indeks }}
                    </td> -->
                      <td class="text-center">
                        <!-- <button
                        data-dismiss="modal"
                        aria-label="Close"
                        class="btn btn-primary btn-sm"
                        v-on:click="pilihRoles(r.id_statusijintinggal, r.detail)"
                      >
                        Pilih
                      </button> -->

                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihRoles(data.id_statusijintinggal, data.jenis_visa)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="page--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
            <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE VISA TYPE</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <!-- <v-col cols="12" v-if="errors.length">
            <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning"> </v-icon>
                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                </div>
              </div>
            </v-alert>
          </v-col> -->
            <v-col cols="2" md="1">
              <v-switch v-model="SwitchCategory" label="New" @click="ResetParent()"></v-switch>
            </v-col>
            <v-col md="5" cols="8">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Parent"
                v-model="visacategory.jenis_visa"
                id="jenis_visa"
                dense
                outlined
                :disabled="SwitchCategory"
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Visa Type"
                v-model="visacategory.id_statusijintinggal"
                id="id_statusijintinggal"
                dense
                outlined
                readonly
                type="hidden"
                :disabled="SwitchCategory"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Visa Type" v-model="jenis_visa" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Detail" v-model="visacategory.detail" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Index" v-model="visacategory.indeks" dense outlined></v-text-field>
            </v-col>
            <!-- <v-col md="6" cols="12">
              <v-text-field
                label="Reminder"
                type="number"
                v-model="visacategory.reminder"
                dense
                outlined
              ></v-text-field>
            </v-col> -->
            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'visacategory-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Visa Type',
          disabled: false,
          href: '/admin/visacategory',
        },
        {
          text: 'Create Visa Type',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      // disabled: 0,
      // jenis_visa_parent: '',
      // disabled: 0,
      SwitchCategory: true,

      visacategory: {
        id_statusijintinggal: '',
        jenis_visa: '',
        detail: '',
        indeks: '',
        // reminder: '',
      },
      jenis_visa: '',

      getvisacategory: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    this.getAllRoles()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    ResetParent() {
      ;(this.visacategory.jenis_visa = ''), (this.visacategory.id_statusijintinggal = '')
    },
    // ubahDisabled(disabled) {
    //   this.visacategory.disabled = !this.visacategory.disabled
    //   // console.log(this.visacategory.disabled)
    // },
    getAllRoles() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/getstatusijintinggals'
      return this.axios.get(url).then(response => {
        this.getvisacategory = response.data.statusijintinggal
      })
    },
    CreateData(e) {
      // console.log('id_statusijintinggal: ' + this.visacategory.id_statusijintinggal)
      console.log('ID STATUS IJIN TINGGAL : ' + this.visacategory.id_statusijintinggal)

      if (this.$data.jenis_visa.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Visa type reqired !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/statusijintinggals/store'
        this.axios
          .post(uri, {
            id_parent: this.visacategory.id_statusijintinggal,
            jenis_visa: this.jenis_visa,
            detail: this.visacategory.detail,
            indeks: this.visacategory.indeks,
            // reminder: this.visacategory.reminder,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Visa category created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
            setTimeout(() => {
              this.$router.push({ name: 'visacategory-index' })
            }, 3000)
          })
          .catch()

        e.preventDefault()
        // setTimeout(() => {
        //   this.$router.push({ name: 'visacategory-index' })
        // }, 3000)
      }
    },
    doSearch() {
      let go = this.getvisacategory.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.jenis_visa.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.getvisacategory)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.getvisacategory.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(getvisacategory) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return getvisacategory.slice(from, to)
    },

    pilihRoles(id_statusijintinggal, jenis_visa) {
      document.querySelector('#id_statusijintinggal').setAttribute('value', id_statusijintinggal)
      document.querySelector('#jenis_visa').setAttribute('value', jenis_visa)
      this.visacategory.id_statusijintinggal = id_statusijintinggal
      this.visacategory.jenis_visa = jenis_visa
    },
  },
  watch: {
    getvisacategory() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
